import React, { useContext, useState } from 'react';
import { CartContext } from '../../provider/cart-provider';
import { LocalCartContext } from '../../provider/local-cart-provider';
import { Spinner, Container } from 'theme-ui'

const WebCheckout = ({onClick, setLoggedIn}) => {
	const { products, totalPrice, currency, clearCart } = useContext(
		LocalCartContext
	);
	const { store, addToCartAndCheckout } = useContext(CartContext);
	const { checkout, updating } = store;
	const handleCheckout = () => {
		addToCartAndCheckout();
		clearCart();
		//onClick();
	};	
    React.useEffect(() => {
    	setLoggedIn(true)
    	handleCheckout();
    }, []);
	//console.log("user: ", user)
	return (
	<Container sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
		<Spinner />
	</Container>
	)
}
export default WebCheckout;