/** @jsx jsx */
import { useContext, useState, useRef, useEffect } from 'react';
import { Box, Flex, Text, Image, jsx, Heading, Button } from 'theme-ui';
import { animated, useSpring, useChain } from 'react-spring';
import useFreezeBodyScroll from '../../hooks/useFreezeBodyScroll';
import { IoIosClose } from 'react-icons/io';
import { ArrowRightWhite, Whatsapp } from '../icons';
import { getLocalState, setLocalState } from '../../helper/local-storage';

const Announcement: React.FC<{ btnProps: any }> = ({ btnProps }) => {
	const [open, set] = useState(false);
	useFreezeBodyScroll(open);

	const cartBtnProps = useSpring({
		config: { mass: 1, tension: 800, friction: 50 },
		opacity: open ? 0 : 1,
		transform: open ? 'translateY(-100%)' : 'translateY(0)',
	});
	const cartOverlayRef = useRef();
	// @ts-ignore
	const { size, ...rest } = useSpring({
		ref: cartOverlayRef,
		config: { mass: 1, tension: 800, friction: 50 },
		from: {
			size: '20%',
			left: '40%',
			opacity: 0,
			top: '100%',
			borderRadius: 10,
		},
		to: {
			opacity: open ? 1 : 0,
			size: open ? '100%' : '20%',
			left: open ? '0%' : '40%',
			bottom: open ? '30px' : '0%',
			top: open ? '30%' : '100%',
			borderRadius: 10,
		},
	});
	const groups = [ 
		{
		  name: "Dear Indus Family, Thank you for your unconditional support to use our products and help Farmers and manufacture in India. Due to ongoing rise in prices, labor shortages and also to keep our prices low we are increasing the minimum pick amount to $25 per order.  We do still offer FREE shipping for all orders over $50.  Thank you for your unwavering support for small business like ours. Best Regards - Indus Team ",
		}
	];
	useChain(
		// @ts-ignore
		open ? [cartBtnProps, cartOverlayRef] : [cartOverlayRef, cartBtnProps],
		[0, open ? 0.1 : 0.2]
	);

	useEffect(() => {
	  const popupwb = getLocalState('popupwb');
	  if (!popupwb) {
	    setTimeout(()=> {set(true)}, 5000);
	  }
		
	}, []);

	return (
		<Box className={open ? 'active' : ''} sx={styles.wrapper}>
			<animated.div
				style={{
					...rest,
					width: size,
					height: size,
					position: 'fixed',
					backgroundColor: 'white',
					pointerEvents: open ? 'all' : 'none',
					zIndex: 9999,
				}}
			>
				<Box sx={styles.content}>
					<Heading as="h4" sx={styles.title}>
						Minimum order value for Pickup
						<Button title="Close" variant="text" onClick={()=> {set(false)}}>
							<IoIosClose />
						</Button>
					</Heading>
					<Flex sx={styles.totalPrice}>
						{
						  groups.map((item) => (
							<Box sx={styles.item}>
								<Heading as="h5">{item.name}</Heading>
							</Box>						  
						  ))						
						}
					</Flex>
					<Box sx={styles.buttonStyle}>
						{(
							<Button onClick={() => {setLocalState('popupwb',true); set(false);}}>
								Don't Show Again
							</Button>
						)}
					</Box>					
				</Box>			
			</animated.div>
		</Box>
	);
};

const styles = {
	wrapper: {
		maxWidth: 960,
		zIndex: 9999,
		margin: '0 auto',
		pointerEvents: 'none',
		width: 'calc(100% - 40px)',
		'&.active': {
			'&::after': {
				content: '""',
				display: 'block',
				width: '100vw',
				height: '100vh',
				backgroundColor: 'rgba(0, 0, 0, 0.8)',
				position: 'fixed',
				left: 0,
				bottom: 0,
			},
		},
	},
	cartBtn: {
		border: 0,
		outline: 0,
		maxWidth: 960,
		height: 50,
		color: 'white',
		padding: '0 30px',
		position: 'fixed',
		pointerEvents: 'all',
		width: 'calc(100% - 40px)',
		borderRadius: 6,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: '#292929',
		boxShadow: '0 6px 12px rgba(0, 0, 0, 0.12)',
		cursor: 'pointer',
		left: 'auto',
		right: 'auto',
		zIndex: 999,
	},
	cartText: {
		alignItems: 'center',
		fontSize: '15px',
		svg: {
			marginRight: 15,
		},
	},
	price: {
		fontSize: '15px',
		fontWeight: 500,
	},
	notFound: {
		textAlign: 'center',
		paddingTop: ['25px', '50px'],
		paddingBottom: ['25px', '50px'],
		img: {
			marginBottom: 30,
		},
		h3: {
			fontSize: 2,
			color: 'primary',
			marginBottom: [12, 15],
		},
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		padding: 10,
		position: 'relative',
		'@media only screen and (max-width: 480px)': {
			padding: '10px 10px 10px',
		},
	},	
	title: {
		color: 'primary',
		fontWeight: '600',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: 750,
		marginX: 'auto',
		button: {
			marginRight: -18,
		},
	},	
	cartItems: {
		width: '100%',
		maxWidth: 780,
		marginX: 'auto',
		overflow: 'hidden',
		flexGrow: '1',
		height: 'calc(100% - 240px)',
		'@media only screen and (max-width: 480px)': {
			height: 'calc(100% - 206px)',
		},
		'> div': {
			paddingRight: [32, 46],
			marginRight: 0,
		    '@media only screen and (max-width: 480px)': {
			    marginRight: -30,
		    },
			paddingLeft: [0, 15],
			height: '100%',
			overflow: 'auto',
			overflowX: 'hidden',
		},
	},	
	totalPrice: {
	    flexDirection: 'column',
		width: 'calc(100% + 60px)',
		padding: '5px 30px',
		marginTop: 0,
		marginLeft: '-30px',
		backgroundColor: 'background',
		position: 'relative',
		'@media only screen and (max-width: 480px)': {
			marginTop: 18,
		},
		'&::after': {
			content: '""',
			position: 'absolute',
			right: 0,
			bottom: -30,
			width: '100%',
			height: '30px',
			background:
				'linear-gradient(to bottom, rgba(255, 255, 255, 0.7) , rgba(255, 255, 255, 0))',
			zIndex: 1,
			pointerEvents: 'none',
			'@media only screen and (max-width: 480px)': {
				display: 'none',
			},
		},
		'>div': {
			width: '100%',
			maxWidth: 750,
			marginX: 'auto',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		h4: {
			fontFamily: 'body',
			fontSize: 1,
			color: 'primary',
		},
		div: {
			fontSize: 1,
		},
		a: {
			color: 'primary',
			fontWeight: '700',
			textDecoration: 'none',
		},		
	},	
	item: {
	   marginBottom: 0,
		'@media only screen and (max-width: 480px)': {
			marginBottom: 18,
		},	   
	},
	buttonStyle: {
		width: '100%',
		maxWidth: 750,
		marginX: 'auto',
		backgroundColor: 'white',
		position: 'relative',
		'&::before': {
			content: '""',
			position: 'absolute',
			right: 0,
			top: -30,
			width: '100%',
			height: '30px',
			background:
				'linear-gradient(to top, rgba(255, 255, 255, 0.7) , rgba(255, 255, 255, 0))',
			zIndex: 1,
			pointerEvents: 'none',
			'@media only screen and (max-width: 480px)': {
				display: 'none',
			},
		},
		button: {
			width: '100%',
			svg: {
				position: 'relative',
				top: '1px',
			},
		},
	}	
};

export default Announcement;
